import React, { useState } from "react"
import Base from "../../components/templates/Base"

import LoginForm from "../../components/Login/Login"
import styled from "styled-components"
import ThemeDefault from "../../components/theme/ThemeDefault"
import device from "../../components/theme/MediaQueries"
import Tick from "../../assets/images/icons/tick.svg";
import Point from "../../assets/images/price-stand-point.svg"
import { Link } from "gatsby"
import Sit from "../../assets/images/price-sit.svg"

import sectionImage1 from "../../images/section-1.png"
import sectionImage2 from "../../images/section-2.png"
import sectionImage3 from "../../images/section-3.png"
import sectionImage4 from "../../images/section-4.png"
import LoadedButton from "../../components/elements/LoadedButton"

const ShowMeTheMoney = ({ location }) => {

	const [selectedCard, setSelectedCard] = useState(null)
 

	return (
		<Base>
			

			<HeroSectionComponent bgColour={'#ffffff'}> 
				<div className="inner-div wrapper hero-inner">
					<div className="title-col">
						<div className="hero-text">Loaded Training Hub <br /> <span style={{fontStyle: 'italic'}}>Paid Team Training Options</span></div>
						<div className="text" style={{marginTop: "1.6rem"}}>Get the most out of Loaded and utlise our team of industry experts.</div>
					</div>
					<div className="title-col">
						<div className="hero-title"><h1>Loaded<span style={{color: '#638cf7'}}>Labs</span></h1></div>
					</div>
				</div>
			</HeroSectionComponent>

      <PageSectionComponent className={`${selectedCard !== null ? 'bottom' : '' }`}  onClick={()=>setSelectedCard(null)} style={{cursor: `${selectedCard !== null ? 'pointer' : 'auto' }`}}>
        <div className="inner-div wrapper"  onClick={()=>setSelectedCard(null)}>

					<div className="c-columns-4 c-columns-l-2 c-columns-m-1 c-columns-gap-m" onClick={()=>setSelectedCard(null)}>
						
						<Card cssClass={`card light ${selectedCard === 2 ? 'fade' : ''}`}>

							<div className="card-content">
								
								<div className="wrap">
									<div className="title">
										<h2>Done For <br /> You</h2>
									</div>
									
									<div className="text">
										Loaded Setup completed for you by the LoadedLabs team.
									</div>
									
									<div className="tierPrice">
										<div className="price">
											<span>$</span>149 <span className="small-text">ex GST</span>
										</div> 
										<div className="currency">
											<small>NZD/HOUR</small>
										</div>
									</div>

									<div className="tierButton">
										<div className="button-wrap">
											<div className="button " onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setSelectedCard(1)}}>View Options</div>
										</div>   
									</div>   

									<div className="items">
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Stock</div>
										</div>
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Time & Attendance</div>
										</div>
									</div>
								</div>


							</div>

							<PointWrapper className="man-1">
								<Point />
							</PointWrapper>

						</Card>

						<Card cssClass={`card dark ${selectedCard === 1 ? 'fade' : ''}`}>

							<div className="card-content">
								
								<div className="wrap">

									<div className="title">
										<h2>Done With <br /> You</h2>
									</div>
									
									<div className="text">
										The LoadedLabs team assists you with Loaded implementation.
									</div>
									
									<div className="tierPrice">
										<div className="price">
											<span>$</span>149 <span className="small-text">ex GST</span>
										</div> 
										<div className="currency">
											<small>NZD/HOUR</small>
										</div>
									</div>
									
									<div className="tierButton">
										<div className="button-wrap">
											<div className="button " onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setSelectedCard(2)}}>View Options</div>
										</div>   
									</div>   

									<div className="items">
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Stock</div>
										</div>
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Time & Attendance</div>
										</div>
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Cashups</div>
										</div>
									</div>
								</div>

							</div>

						</Card>

						{ selectedCard !== null &&  <SubCards injected={true} selectedCard={selectedCard} /> }

						<Card cssClass={`card yellow  ${selectedCard !== null ? 'fade' : ''}`}>

							<div className="card-content">
								<div className="wrap">

									<div className="title">
										<h2>One-on-One <br /> Training</h2>
									</div>
									
									<div className="text">
										Expert training with a member of the LoadedLabs team
									</div>
									
									<div className="tierPrice">
										<div className="price">
											<span>$</span>149 <span className="small-text">ex GST</span>
										</div> 
										<div className="currency">
											<small>NZD/HOUR</small>
										</div>
									</div>

									<div className="tierButton">
										<div className="button-wrap">
											<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
												<div className="button open" >Learn More</div>
											</a>
										</div>   
									</div>   								

									<div className="items">
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Online Training</div>
										</div>
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">In Person Training</div>
										</div>

									</div>
								</div>
							

							</div>

						</Card>



						<Card cssClass={`card light  ${selectedCard !== null ? 'fade' : ''}`}>

							<div className="card-content">
								<div className="wrap">

									<div className="title">
										<h2>Business <br /> Coaching</h2>
									</div>
									
									<div className="text">
										High performance business coaching for those chasing world class results.
									</div>
									
									<div className="tierPrice">
										<div className="price">
											<span>$</span>149 <span className="small-text">ex GST</span>
										</div> 
										<div className="currency">
											<small>NZD/HOUR</small>
										</div>
									</div>

									<div className="tierButton">
										<div className="button-wrap">
											<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
												<div className="button open" >Learn More</div>
											</a>
										</div>   
									</div> 

									<div className="items">
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Full Business Coaching</div>
										</div>
										<div className="feature" >
											<div className="tick"><Tick /></div>
											<div className="feature-text">Delivered by hospitality experts</div>
										</div>
									</div>
								</div>

							</div>

							<PointWrapper className="man-2">
								<Point />
							</PointWrapper>

						</Card>

					</div>

				</div>
      </PageSectionComponent>



		{ selectedCard !== null && 
      <PageSectionComponent className={'top'} id="sub" onClick={()=>setSelectedCard(null)}  style={{cursor: `${selectedCard !== null ? 'pointer' : 'auto' }`}}>
        <div className="inner-div wrapper"  onClick={()=>setSelectedCard(null)}>

					<div className="c-columns-4 c-columns-l-2 c-columns-m-1 c-columns-gap-m"  onClick={()=>setSelectedCard(null)}>
						
						 <SubCards selectedCard={selectedCard} /> 

					</div>

				</div>
      </PageSectionComponent> }

		</Base>
	)
}

const SubCards = ({selectedCard, injected}) => {

	console.log(injected)
	
	return (
		<>
			<Card cssClass={`card ${selectedCard === 1 ? 'light' : 'dark'} ${injected ? 'injected': ''}`}>

				<div className="card-content">
					
					<div className="wrap">

						<div className="title">
							<h2>Stock</h2>
						</div>

						<div className="text">
							We do a full stock set-up for you of everything
						</div>
						
						<div className="tierPrice">
							<div className="price">
								<span>$</span>149 <span className="small-text">ex GST</span>
							</div> 
							<div className="currency">
								<small>NZD/HOUR</small>
							</div>
						</div>

						<div className="button-wrap">
							<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
								<div className="button">
									Get Started
								</div>
							</a>
						</div>


						<div className="items">
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Recipes</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Menus</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">POS Integration</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Ordering</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Stocktaking</div>
							</div>
						</div>



					</div>
					

				</div>


			</Card>

			<Card cssClass={`card ${selectedCard === 1 ? 'light' : 'dark'} ${injected ? 'injected': ''}`}>

				<div className="card-content">
					
					<div className="wrap">

						<div className="title">
							<h2>Time & Attendance</h2>
						</div>
						
						<div className="text">
							We do a full Time & Attendance set-up for you of everything
						</div>
						
						<div className="tierPrice">
							<div className="price">
								<span>$</span>149 <span className="small-text">ex GST</span>
							</div> 
							<div className="currency">
								<small>NZD/HOUR</small>
							</div>
						</div>

						<div className="button-wrap">
							<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
								<div className="button">
									Get Started
								</div>
							</a>
						</div>

						<div className="items">
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Staff</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Rosters</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Timesheets</div>
							</div>
						</div>


					</div>

				</div>

			</Card>

			{ selectedCard === 2 && <Card cssClass={`card dark ${injected ? 'injected': ''}`}>

				<div className="card-content">
					<div className="wrap">

						<div className="title">
							<h2>Cashups</h2>
						</div>

						<div className="text">
							We do a full cash up set-up for you of everything
						</div>
						
						<div className="tierPrice">
							<div className="price">
								<span>$</span>149 <span className="small-text">ex GST</span>
							</div> 
							<div className="currency">
								<small>NZD/HOUR</small>
							</div>
						</div>

						<div className="button-wrap">
							<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
								<div className="button">
									Get Started
								</div>
							</a>
						</div>

						<div className="items">
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Sync your revenue</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Reconciliation</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Reporting</div>
							</div>
							<div className="feature" >
								<div className="tick"><Tick /></div>
								<div className="feature-text">Multi-site comparison</div>
							</div>

						</div>

					</div>
					
				</div>

			</Card> }

			{
				injected && 			
				<>

					<Card cssClass={`blank ${injected ? 'injected': ''}`}>
						&nbsp;
					</Card>
					<Card cssClass={`blank ${injected ? 'injected': ''}`}>
						&nbsp;
					</Card>
					<Card cssClass={`blank ${injected ? 'injected': ''}`}>
						&nbsp;
					</Card>

				</>

			}

		</>
	)
}


export default ShowMeTheMoney

const PointWrapper = styled.div`
	position: absolute;
	width: 100px;
	left: 0;
	bottom: 0;
	transform: translate(-80px,0px);		

	&.man-1 {
		transform: translate(-80px,0px) scaleX(-1);		
	}
	&.man-2 {
		left: auto;
		right: 0;
		transform: translate(80px,0px) scaleX(1);		
	}

	@media ${device.MXlg} {
		display: none;
	}	
`

const Card = styled.div.attrs((props) => ({
  className: props.cssClass,
}))`
  
	position: relative;
	padding: 16px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
	border-radius: 8px;
	height: 100%;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	transition: 350ms;
	
	.small-text {
		font-size: 11px;
		margin-left: 2px;
		letter-spacing: 0;
	}
		
	.tierTitle,
	.tierPrice,
	.tierText,
	.tierButton {
		justify-content: center;
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-bottom: 16px;
	}

	.tierTitle {
		text-align: center;
		font-family:  ${ThemeDefault.fontSecondary};
		font-size:  ${ThemeDefault.font_4};
		line-height: 1.4;
		letter-spacing: -1px;
		font-weight: 400;
	}

	.feature {
		display: flex;
		flex-direction: row;
		align-items: center;
		line-height: 1.2;
		margin: 8px 0;
		padding: 8px;
		position: relative;


		&:after {
			content: '';
			position: absolute;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			width: 80%;
			bottom: -4px;
			left: 50%; 
			transform: translateX(-50%);
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	.tick {
		color: ${ThemeDefault.blue};
		width: 16px; 
		margin-right: 8px;
		flex-shrink: 0;
		flex-grow: 0;
	}

	.tierPrice {
		.price {
			font-size: ${ThemeDefault.font_6};
			letter-spacing: 1px;
			line-height: 1;
		}
		.currency {
			padding-top: 4px;
			margin-left: 2px;
		}
	}

	&.blank {
		box-shadow: none;
		margin-bottom: 2.4rem;
	}

	&.injected {
		@media (min-width: 992px) {
			display: none;
		}
	}

	/* & .feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.2;
    margin: 8px 0;
    padding: 8px;
    position: relative;

  }

  .tick {
    color: ${ThemeDefault.blue};
    width: 16px; 
    margin-right: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  } */


	& .card-content {
		align-content: space-between;
		display: grid;
		height: 100%;

		@media ${device.MXmd} {
			padding: 3.2rem;
		}

		@media ${device.MXsm} {
			padding: 3.2rem 1.6rem;
		}

		& .feature-text {
      font-size: ${ThemeDefault.font_0};
		}
		
		& .items {
			margin: 1.6rem 0 ;
			/* padding: 0 1.6rem; */

			@media ${device.MXmd} {
				padding: 0;	
			}
		}

		& .text {
      font-size: ${ThemeDefault.font_0};
			margin-bottom: 3.2rem;
			padding: 0 1.6rem;
			text-align: center;
		}

		& ul {
      font-size: ${ThemeDefault.font_0};
		}
	}

	& .price {
		margin-top: 0.8rem;
	}
	
	&.light {
		background-color: #ffffff;
	}
	&.dark {
		background-color: #eaeaea;
	}
	&.disabled {
		opacity: 0.4;
		cursor: no-drop;
		background-color: #d4d4d2;
		& a {
			pointer-events: none;
		}
	}


  .title {

    h2 {
      font-family: ${ThemeDefault.fontSecondary};
      font-size: ${ThemeDefault.font_3};
      font-weight: 400;
      letter-spacing: -1px;
      margin-bottom: 32px;
			text-align: center;
    }

  }

  .button-wrap {
    /* margin-bottom: 16px; */
		text-align: center;

    .button {
      display: block;
      padding: 8px 16px;
      background-color: ${ThemeDefault.blue};
      border: 1px solid ${ThemeDefault.blue};
      color: ${ThemeDefault.white};
      letter-spacing: 1px;
      font-size: ${ThemeDefault.font__1};
			cursor: pointer;

			&.text-button {
				border: 1px solid transparent;
				background-color: transparent;
				color: ${ThemeDefault.black};
			}

			&.open {
				border: 1px solid ${ThemeDefault.black};
				background-color: transparent;
				color: ${ThemeDefault.black};
			}
    }
  }

	&.yellow {
		background: #EBDAB4;
	}

	&.fade {
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}
` 

const PageSectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

	&:not(.top) .inner-div {
		padding-top: 0 !important;
	}

	&.top {
		@media (max-width: 992px) {
			display: none;
		}

		& .inner-div {
			@media (min-width: 992px) {
				padding-top: clamp(12px, 3.5vmin, 30px);
			}
		}
	}

	&.bottom {
		& .inner-div {
			@media (min-width: 992px) {
				padding-bottom: 0;
			}
		}
	}
	& .col {

		& h2 {
			text-align: center;
			font-family: ${ThemeDefault.fontSecondary};
			color: ${ThemeDefault.black};
		}

		&.light{

		}
		&.dark{

		}

	}

`

const HeroSectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-top: 4px;

  & .hero-inner {
    
		
		&.inner-div {
			padding: 0 !important;
      padding-bottom: 0 !important;
			
      & .gatsby-image-wrapper {
				height: 100%;
      }
    }
		
    flex-direction: row;
    display: flex;
		align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 16px;
      text-align: left;
      font-weight: 500;

      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .spacer {
      margin-bottom: 64px;

      @media ${device.MXmd} {
        margin-bottom: 32px;
      }
    }

    .title-col {
      padding-top: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      padding-left: calc(24px + 24px);
      padding-right: calc(24px + 24px);
      padding-bottom: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      width: 50%;

      @media ${device.MXmd} {
        padding: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px) 24px;
        width: 60%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }

    .hero-title {
			& h1 {
				font-family: ${ThemeDefault.fontPrimary};
				color: ${ThemeDefault.black};
				margin: 0;
				font-size: 7rem;
				letter-spacing: -6px;
			}
		}

    .hero-text {
      text-align: left;
      font-size: var(--font-3);
      padding-right: 64px;
			font-family: ${ThemeDefault.fontSecondary};
			color: ${ThemeDefault.black};

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.black};
      /* color: ${ThemeDefault.blue}; */

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

    .hero-button-row {
      flex-direction: row;
      display: flex;
      align-items: center;
      
      @media ${device.MXmd} {
        flex-wrap: wrap;
      }
      
      p {
        margin: 0;
      }
      
    }

    .image-col {
      width: 50%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
			& picture,
			& img {
				object-fit: ${props => props.imageColContain ? 'contain !important' : 'cover !important'}
			}

      @media ${device.MXmd} {
        width: 40%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }
      
    }

  }
`